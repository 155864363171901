import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { InvoiceService } from 'src/app/services/invoices.service';

@Component({
  selector: 'app-dialog-first-month-rental-fee',
  templateUrl: './dialog-first-month-rental-fee.component.html',
  styleUrls: ['./dialog-first-month-rental-fee.component.scss'],
})
export class DialogFirstMonthRentalFeeComponent implements OnInit {
  form = new UntypedFormGroup({
    rental_fee: new UntypedFormControl('', Validators.required),
  });
  contractId: string;
  roomId: string;

  constructor(
    public dialogRef: MatDialogRef<DialogFirstMonthRentalFeeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private invoiceService: InvoiceService
  ) {
    this.contractId = this.data.data;
    this.roomId = this.data.roomId;
  }

  ngOnInit(): void {}

  submit() {
    if (this.form.valid) {
      let body = {
        contract_id: this.contractId,
        amount: Number(this.form.value.rental_fee),
      };
      if (body.amount === 0) {
        this.dialogRef.close();
        this.router.navigate([
          `information/${this.roomId}/contracts/move-in/${this.contractId}`,
        ]);
      } else {
        this.dialogRef.close();
        this.invoiceService.createInvoiceMoveIn(body).subscribe((res: any) => {
          // this.router.navigate([`move/invoice-move-in/${res._id}`]);
          this.router.navigate([
            `information/${this.roomId}/invoices/move-in/${res._id}`,
          ]);
        });
      }
    }
  }
}
