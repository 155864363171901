import { Injectable, Injector } from '@angular/core';
import { map } from 'rxjs/operators';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(injector: Injector, private requestService: RequestService) {
    // super(injector)
    // this.path = '/locations'
  }

  getUser(data: any) {
    return this.requestService
      .getJSON(`/users`, { data, is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getEmployees(data: any) {
    return this.requestService
      .getJSON(`/users/employees`, { data, is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  patchUser(id: string, data: any) {
    return this.requestService
      .patchFile(`/users/${id}`, { data, is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  postUser(data: any) {
    return this.requestService
      .postFile(`/users`, { data, is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  deleteUser(id: string) {
    return this.requestService.deleteJSON(`/users/${id}`).pipe(
      map((res) => {
        return res;
      })
    );
  }

  updateLanguageForUser(id: string, data: any) {
    return this.requestService
      .patchJSON(`/users/${id}/lang`, { data, is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
