import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { MongoObject } from '../models/mongo-object';
import { LocalStorageService } from './local-storage.service';
import { LocalStorageEnum } from '../models/enums/local-storage.enum';
@Injectable({
  providedIn: 'root',
})
export class RequestsService {
  constructor(
    private requestService: RequestService,
    private localStorageService: LocalStorageService
  ) {}

  getRequests(data: any) {
    return this.requestService.getJSON(`/requests`, {
      is_loading: true,
      data,
    });
  }

  updateRequest(_id: string, data: any) {
    let obj = data as MongoObject;
    delete obj._id;
    return this.requestService.patchJSON(`/requests/${_id}`, {
      data: data,
    });
  }

  getOneRequest(_id: string, data?: any) {
    return this.requestService.getJSON(`/requests/${_id}`, {
      is_loading: true,
      data: data,
    });
  }

  deleteRequest(_id: string) {
    let organization = this.localStorageService.get(
      LocalStorageEnum.organization_id
    );
    return this.requestService.deleteJSON(`/requests/${_id}`, {
      data: {
        organization: organization,
      },
    });
  }

  getPendingRequests(data: any) {
    return this.requestService.getJSON(`/requests/number-pending-requests`, {
      is_loading: true,
      data,
    });
  }
}
