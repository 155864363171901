import { Component, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'admin';
  isLoading = false;
  loadingTimeout: any;
  constructor(private router: Router, public loadingService: LoadingService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loadingService.forceStop();
        this.loadingService.setLoading(true);
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel
      ) {
        // scroll to top on navigate finish
        // window.scrollTo({top:0})
        this.loadingService.setLoading(false);
      }
    });
    // delay to hide some quick loading
    this.loadingService.isLoading$.subscribe((isLoading: boolean) => {
      if (this.loadingTimeout) {
        clearTimeout(this.loadingTimeout);
      }
      this.loadingTimeout = setTimeout(() => {
        this.isLoading = isLoading;
        this.loadingTimeout = null;
      }, 200);
    });
  }

  ngOnInit(): void {
    this.loadScript();
  }

  private loadScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.google_maps_api_key}&libraries=places`;
    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(script);
    }
  }
}
