import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
const now = new Date();
@Pipe({
  name: 'calculateDate',
})
export class CalculateDatePipe implements PipeTransform {
  constructor(public translate: TranslateService) {}

  transform(start: string, end: string, format: string = 'default'): string {
    let valueDateStart = new Date(start);
    let valueDateEnd = new Date(end);
    let result;
    let day: number = 0;
    let d, fm, m, y, fullDate;
    if (valueDateStart && valueDateEnd) {
      d = valueDateEnd.getDate();
      if (d < 10) d = '0' + d;
      else d = d;
      m = valueDateEnd.getMonth() + 1;
      switch (m) {
        case m:
          1;
          fm = this.translate.instant('Jan');
          break;
        case m:
          2;
          fm = this.translate.instant('Feb');
          break;
        case m:
          3;
          fm = this.translate.instant('Mar');
          break;
        case m:
          4;
          fm = this.translate.instant('Apr');
          break;
        case m:
          5;
          fm = this.translate.instant('May');
          break;
        case m:
          6;
          fm = this.translate.instant('Jun');
          break;
        case m:
          7;
          fm = this.translate.instant('Jul');
          break;
        case m:
          8;
          fm = this.translate.instant('Aug');
          break;
        case m:
          9;
          fm = this.translate.instant('Sep');
          break;
        case m:
          10;
          fm = this.translate.instant('Oct');
          break;
        case m:
          11;
          fm = this.translate.instant('Nov');
          break;
        case m:
          12;
          fm = this.translate.instant('Dec');
          break;
        default:
          fm = 'Null';
          break;
      }
      // if(m<10)m='0'+m;
      // else m = m;
      y = valueDateEnd.getFullYear();
      fullDate = d + ' ' + fm + ' ' + y;
      result = valueDateEnd.getTime() - valueDateStart.getTime();
      if (result <= 0) {
        day = Math.round(result / (1000 * 3600 * 24)); //culate date
        let y = Math.round(day / 365);
        let m = Math.round((day / 365) * 12);
        let w = Math.round(day / 7);
        if (format === 'di') {
          return day.toString();
        }
        //format day
        else if (format === 'd') {
          if (day == 1) {
            return day.toString() + ' ' + this.translate.instant('day');
          } else {
            return day.toString() + ' ' + this.translate.instant('days');
          }
        }
        //format under one week
        else if (format === 'uow') {
          if (day === 7) {
            return '1' + ' ' + this.translate.instant('week');
          } else if (day < 7) {
            if (day === 1) {
              return day.toString() + ' ' + this.translate.instant('day');
            }
            return day.toString() + ' ' + this.translate.instant('days');
          }
        }
        //format under two weeks
        else if (format === 'utw') {
          if (day === 7) {
            return '1' + ' ' + this.translate.instant('week');
          } else if (day > 7 && day < 14) {
            if (day % 7 === 1) {
              return (
                '1' +
                ' ' +
                this.translate.instant('week') +
                '1' +
                ' ' +
                this.translate.instant('day')
              );
            } else if (day % 7 === 0) {
              return '1' + ' ' + this.translate.instant('week');
            } else {
              return (
                '1' +
                ' ' +
                this.translate.instant('week') +
                (day % 7).toString() +
                ' ' +
                this.translate.instant('days')
              );
            }
          } else if (day === 14) {
            return '2' + ' ' + this.translate.instant('weeks');
          } else if (day > 7) {
            if (day === 1) {
              return day.toString() + ' ' + this.translate.instant('day');
            } else return day.toString() + ' ' + this.translate.instant('days');
          }
        }
        //format week
        else if (format === 'w') {
          if (w === 1) {
            return '1' + ' ' + this.translate.instant('week');
          } else {
            return w.toString() + ' ' + this.translate.instant('weeks');
          }
        }
        //format month
        else if (format === 'm') {
          if (m == 1) {
            return m.toString() + ' ' + this.translate.instant('month');
          } else {
            return m.toString() + ' ' + this.translate.instant('months');
          }
        }
        //format year
        else if (format === 'y') {
          if (y == 1) {
            return y.toString() + ' ' + this.translate.instant('year');
          } else {
            return y.toString() + ' ' + this.translate.instant('years');
          }
        }
        //format month and year
        else if (format === 'my') {
          if (day < 365) {
            if (m == 1) {
              return m.toString() + ' ' + this.translate.instant('month');
            } else if (m > 1) {
              return m.toString() + ' ' + this.translate.instant('months');
            }
          }
          if (day >= 365) {
            if (y == 1) {
              return y.toString() + ' ' + this.translate.instant('year');
            } else {
              return y.toString() + ' ' + this.translate.instant('years');
            }
            //return day.toString()
          }
        } else {
          if (day > 31) {
            return fullDate.toString();
          } else {
            if (day == 1) {
              return day.toString() + ' ' + this.translate.instant('day');
            }
            return day.toString() + ' ' + this.translate.instant('days');
          }
        }
      } else {
        day = Math.round(result / (1000 * 3600 * 24)); //culate date
        let y = Math.round(day / 365);
        let m = Math.round((day / 365) * 12);
        let w = Math.round(day / 7);
        if (format === 'di') {
          return day.toString();
        }
        //format day
        else if (format === 'd') {
          if (day == 1) {
            return day.toString() + ' ' + this.translate.instant('day');
          } else {
            return day.toString() + ' ' + this.translate.instant('day');
          }
        }
        //format under one week
        else if (format === 'uow') {
          if (day === 7) {
            return '1' + ' ' + this.translate.instant('week');
          } else if (day < 7) {
            if (day === 1) {
              return day.toString() + ' ' + this.translate.instant('day');
            }
            return day.toString() + ' ' + this.translate.instant('days');
          }
        }
        //format under two weeks
        else if (format === 'utw') {
          if (day === 7) {
            return '1' + ' ' + this.translate.instant('week');
          } else if (day > 7 && day < 14) {
            if (day % 7 === 1) {
              return (
                '1 ' +
                ' ' +
                this.translate.instant('week') +
                '1' +
                ' ' +
                this.translate.instant('day')
              );
            } else if (day % 7 === 0) {
              return '1' + ' ' + this.translate.instant('week');
            } else {
              return (
                '1' +
                ' ' +
                this.translate.instant('week') +
                (day % 7).toString() +
                ' ' +
                this.translate.instant('days')
              );
            }
          } else if (day === 14) {
            return '2' + ' ' + this.translate.instant('weeks');
          } else if (day > 7) {
            if (day === 1) {
              return day.toString() + ' ' + this.translate.instant('day');
            } else return day.toString() + ' ' + this.translate.instant('days');
          }
        }
        //format week
        else if (format === 'w') {
          if (w === 1) {
            return '1' + ' ' + this.translate.instant('week');
          } else {
            return w.toString() + ' ' + this.translate.instant('weeks');
          }
        }
        //format month
        else if (format === 'm') {
          if (m == 1) {
            return m.toString() + ' ' + this.translate.instant('month');
          } else {
            return m.toString() + ' ' + this.translate.instant('months');
          }
        }
        //format year
        else if (format === 'y') {
          if (y == 1) {
            return y.toString() + ' ' + this.translate.instant('year');
          } else {
            return y.toString() + ' ' + this.translate.instant('years');
          }
        }
        //format month and year
        else if (format === 'my') {
          if (day < 365) {
            if (m == 1) {
              return m.toString() + ' ' + this.translate.instant('month');
            } else if (m > 1) {
              return m.toString() + ' ' + this.translate.instant('months');
            }
          }
          if (day >= 365) {
            if (y == 1) {
              return y.toString() + ' ' + this.translate.instant('year');
            } else {
              return y.toString() + ' ' + this.translate.instant('years');
            }
            //return day.toString()
          }
        } else {
          if (day > 31) {
            return fullDate.toString();
          } else {
            if (day == 1) {
              return day.toString() + ' ' + this.translate.instant('day');
            }
            return day.toString() + ' ' + this.translate.instant('days');
          }
        }
      }
    }
    return '';
  }
}
