import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
const baseUrl = environment.api_url;
@Pipe({
  name: 'roomFile',
})
export class RoomFilePipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    if (value && value != 'null') {
      return baseUrl + '/files/' + value;
    }
    return '/assets/imgs/default-room.jpg';
  }
}
