import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
const baseUrl = environment.api_url;
@Pipe({
  name: 'urlFile',
})
export class UrlFilePipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    if (value && value != 'null') {
      return baseUrl + '/files/' + value;
    }
    return '/assets/imgs/default-image.svg';
  }
}
