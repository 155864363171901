<form class="form-field" [formGroup]="form" (ngSubmit)="submit()">
  <div class="content">
    <div class="title">First month rental fee</div>
    <div>Rental fee</div>
    <mat-form-field class="full-width" appearance="outline">
      <div class="input-display">
        <input matInput formControlName="rental_fee" type="text" />
        <div class="unit-size">USD</div>
      </div>
      <mat-error *ngIf="form.controls.rental_fee.hasError('required')">
        Rental fee is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>
  <mat-dialog-actions align="end">
    <button
      mat-raised-button
      class="primary-button px-5"
      color="primary"
      type="submit"
    >
      Confirm
    </button>
  </mat-dialog-actions>
</form>
