import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SearchService {
  private visibilityChanges = new BehaviorSubject<boolean>(false);
  public textChanges = new BehaviorSubject<string>('62f9b21f33bd0b87f2b7c331');
  public initText = new BehaviorSubject<string>('');

  public setVisibility(visibility: boolean) {
    this.visibilityChanges.next(visibility);
  }

  public getVisibility(): boolean {
    return this.visibilityChanges.value;
  }

  public visibilityObservable(): Observable<boolean> {
    return this.visibilityChanges.asObservable();
  }

  public emitText(text: string) {
    if (text && text != '' && text != 'null') this.textChanges.next(text);
  }

  public setInitText(text: string) {
    if (text && text != '' && text != 'null') this.initText.next(text);
  }

  public searchObservable(): Observable<string> {
    return this.textChanges.asObservable();
  }
}
