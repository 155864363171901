import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalculateDatePipe } from './pipes/date';
import { MonthFormat } from './pipes/month';
import { DateAgoPipe } from './pipes/dateAgo';
@NgModule({
  declarations: [CalculateDatePipe,MonthFormat,DateAgoPipe],
  imports: [
    CommonModule
  ],
  exports:[
    CalculateDatePipe,
    MonthFormat,
    DateAgoPipe
  ]
})
export class DateModule { }
