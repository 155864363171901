import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserRoleEnum } from '../models/enums/user-role.enums';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.authService.isAuth) {
      this.router.navigate(['/login']);
      return false;
    } else if (
      !next.data.roles ||
      (next.data.roles as string[])?.includes(this.authService.userRole)
    ) {
      return true;
    } else if (this.authService.userRole == UserRoleEnum.super_admin) {
      if (
        state.url == '/dashboard' ||
        state.url == '/organizations' ||
        state.url == '/managers'
      ) {
        return true;
      } else {
        this.router.navigate(['/dashboard']);
        return false;
      }
    } else if (this.authService.userRole == UserRoleEnum.agent) {
      if (state.url == '/properties' || state.url == '/clients') {
        return true;
      } else {
        this.router.navigate(['/properties']);
        return false;
      }
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}
