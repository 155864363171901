import { Pipe, PipeTransform } from '@angular/core';
const now = new Date();
@Pipe({
  name: 'dateAgo'
})
export class DateAgoPipe implements PipeTransform{
    transform(value: any, ...args: any[]) {
        if(value){
            const sec = Math.floor((+now - +new Date(value))/1000);
            const min = Math.round(sec/60);
            const hour = Math.round(min/60);
            const day = Math.round(hour/24);
            if(sec < 29) return 'Just now';
            else if(min <= 60) return min + 'min ago';
            else if(hour <= 12){
                if(hour === 1) return hour + 'hour ago';
                else return hour + 'hours ago';
            }
            else if(day <=7){
                if(day === 1) return day + 'day ago';
                else return day + 'days ago';
            }

        }
        return new Date(value).toDateString();
    }
}