import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
Sentry.init({
  dsn: 'https://5615d46b31f148df9c3cd2bcb688ce93@sentry.optistech.com/4',
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: [
        'localhost',
        'https://rental-web.myoptistech.com',
        'https://admin.bontub.com',
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 1.0,
});
if (environment.production) {
  enableProdMode();
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => {})
  .catch((err) => console.error(err));
