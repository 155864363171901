import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { LocalStorageService } from './local-storage.service';
import { LocalStorageEnum } from '../models/enums/local-storage.enum';
import { MongoObject } from '../models/mongo-object';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(
    private requestService: RequestService,
    private localStorageService: LocalStorageService
  ) {}

  /**
   * Get all locations
   * @param data
   * @returns {Observable<any>}
   * @memberof LocationService
   */
  getLocations(data?: any) {
    let finalData = {};

    let organization = this.localStorageService.get(
      LocalStorageEnum.organization_id
    );

    if (organization) {
      finalData = {
        ...data,
        organization: organization,
      };
    } else {
      if (data) {
        finalData = { ...data };
      }
    }

    return this.requestService.getJSON('/locations', {
      is_loading: true,
      data: finalData,
    });
  }

  getLocationsByOrganization(data: any) {
    let organization = this.localStorageService.get(
      LocalStorageEnum.organization_id
    );
    return this.requestService.getJSON(`/locations`, {
      is_loading: true,
      data: {
        organization: organization,
        ...data,
      },
    });
  }

  createLocation(data: any) {
    return this.requestService.postFile('/locations', {
      data: data,
    });
  }

  getLocationById(id: string) {
    return this.requestService
      .getJSON(`/locations/${id}`, { is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  updateLocation(_id: string, data: string) {
    let obj = data as MongoObject;
    delete obj._id;
    return this.requestService.patch('/locations/' + _id, {
      data: data,
    });
  }

  deleteLocation(_id: string) {
    let organization = this.localStorageService.get(
      LocalStorageEnum.organization_id
    );
    return this.requestService.deleteJSON('/locations/' + _id, {
      data: {
        organization: organization,
      },
    });
  }
}
