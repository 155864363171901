import { MongoObject } from './../models/mongo-object';
import { Injectable, Injector } from '@angular/core';
import { RequestService } from './request.service';
import { map } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { LocalStorageEnum } from '../models/enums/local-storage.enum';

@Injectable({
  providedIn: 'root',
})
export class RoomService {
  constructor(
    private requestService: RequestService,
    private localStoageService: LocalStorageService
  ) {}

  getAllRooms(data: any) {
    let organization = this.localStoageService.get(
      LocalStorageEnum.organization_id
    );
    return this.requestService.getJSON(`/rooms`, {
      is_loading: true,
      data: {
        organization: organization,
        ...data,
      },
    });
  }

  createRoom(data: any) {
    return this.requestService.postFile(`/rooms`, {
      is_loading: true,
      data: data,
    });
  }

  deleteRoom(id: string) {
    let organization = this.localStoageService.get(
      LocalStorageEnum.organization_id
    );
    return this.requestService.deleteJSON(`/rooms/${id}`, {
      is_loading: true,
      data: {
        organization: organization,
      },
    });
  }

  getOverview(roomId: string) {
    return this.requestService
      .getJSON(`/rooms/${roomId}/overview`, { is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getPendingInfo(roomId: string) {
    return this.requestService
      .getJSON(`/rooms/${roomId}/pending-info`, { is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getRoom(id: string) {
    return this.requestService.getJSON(`/rooms/${id}`, {
      is_loading: true,
    });
  }

  getRoomByFloor(buidlingId: string) {
    return this.requestService.getJSON(`/rooms/floors/${buidlingId}`, {
      is_loading: true,
    });
  }

  updateRoom(id: string, data: any) {
    let obj = data as MongoObject;
    delete obj._id;
    return this.requestService.patch('/rooms/' + id, {
      data: data,
    });
  }

  getRoomStatic(data: any) {
    return this.requestService.getJSON(`/rooms/room-statistic`, {
      is_loading: true,
      data,
    });
  }

  postRoomMoveIn(id: string, data?: any) {
    return this.requestService.postFile(`/rooms/${id}/move-in`, {
      data,
      is_loading: true,
    });
  }

  postRoomMoveOut(_id: string) {
    return this.requestService.postJSON(`/rooms/${_id}/move-out`, {
      is_loading: true,
    });
  }

  getRoomForUtility(data: any) {
    return this.requestService
      .getJSON(`/utility-records`, { data, is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getRoomUtilityRecord = (data: { location: string; building?: string }) => {
    return this.requestService.getJSON('/rooms/occupied', {
      data: data,
      is_loading: true,
    });
  };

  bulkUpdate(data: any) {
    return this.requestService
      .patchJSON(`/rooms/bulk-update`, { data, is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  postRoomAvailable(data: any) {
    return this.requestService.postJSON(`/rooms/available`, {
      data,
      is_loading: true,
    });
  }

  roomAvailableDetail(location_id: string, data: any) {
    return this.requestService.postJSON(`/rooms/available/${location_id}`, {
      data,
      is_loading: true,
    });
  }

  assignClients(room_id: string, data: any) {
    return this.requestService.postJSON(`/rooms/${room_id}/assign-clients`, {
      data,
      is_loading: true,
    });
  }
}
