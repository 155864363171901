import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { LocalStorageService } from './local-storage.service';
import { LocalStorageEnum } from '../models/enums/local-storage.enum';
import { MongoObject } from '../models/mongo-object';

@Injectable({
  providedIn: 'root',
})
export class BuildingService {
  organizationID: string;
  constructor(
    private requestService: RequestService,
    private localStoageService: LocalStorageService
  ) {
    this.organizationID = localStoageService.get(
      LocalStorageEnum.organization_id
    );
  }

  /**
   * Get all buildings
   * @param  {any} data
   * @returns {Observable<any>}
   */
  getBuildings(data: any) {
    return this.requestService.getJSON('/buildings', {
      is_loading: true,
      data: {
        organization: this.organizationID,
        ...data,
      },
    });
  }

  /**
   * Create building
   * @param  {any} data
   * @returns {Observable<any>}
   */
  createBuilding(data: any) {
    return this.requestService.postFile('/buildings', {
      data: data,
    });
  }

  /**
   * Get building by id
   * @param  {string} _id
   * @param  {any} data?
   * @returns {Observable<any>}
   */
  getBuildingById(_id: string, data?: any) {
    return this.requestService.get('/buildings/' + _id, {
      data: data,
    });
  }

  /**
   * Update building
   * @param  {string} _id
   * @param  {any} data
   * @returns {Observable<any>}
   */
  updateBuilding(_id: string, data: any) {
    let obj = data as MongoObject;
    delete obj._id;
    return this.requestService.patch('/buildings/' + _id, {
      data: data,
    });
  }

  /**
   * Delete building
   * @param  {string} b_id
   * @param  {any} data
   * @returns {Observable<any>}
   */
  deleteBuilding(_id: string) {
    let organization = this.localStoageService.get(
      LocalStorageEnum.organization_id
    );
    return this.requestService.deleteJSON('/buildings/' + _id, {
      data: {
        organization: organization,
      },
    });
  }

  /**
   * Get building by id
   * @param  {string} b_id
   * @param  {any} data?
   */
  getRoomsInfo(b_id: string) {
    return this.requestService.getJSON(`/rooms/floors/${b_id}`, {
      is_loading: true,
    });
  }
}
