import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-snackbar-custom',
  templateUrl: './snackbar-custom.component.html',
  styleUrls: ['./snackbar-custom.component.scss']
})
export class SnackbarCustomComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data:any) {}

  ngOnInit(): void {

  }

}
