import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ContainerComponent } from './components/container/container.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { DialogRoomDetailComponent } from './components/dialog-room-detail/dialog-room-detail.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SnackbarCustomComponent } from './components/snackbar-custom/snackbar-custom.component';
import { DialogPaymentSuccessComponent } from './components/dialog-payment-success/dialog-payment-success.component';
import { DialogFirstMonthRentalFeeComponent } from './components/dialog-first-month-rental-fee/dialog-first-month-rental-fee.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { AuthInterceptor } from './intercptors/auth.interceptor';
import { ResetPasswordDialogComponent } from './components/reset-password-dialog/reset-password-dialog.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { UrlFileModule } from './shares/file/urlFile.module';
import { DateModule } from './shares/date/date.module';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatBadgeModule } from '@angular/material/badge';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    ContainerComponent,
    DialogRoomDetailComponent,
    SnackbarCustomComponent,
    DialogPaymentSuccessComponent,
    DialogFirstMonthRentalFeeComponent,
    ResetPasswordDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatSelectModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatDialogModule,
    MatDividerModule,
    MatGridListModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatTooltipModule,
    UrlFileModule,
    DateModule,
    MatBadgeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],

  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    /**
     * Is used for refresh token
     */
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    DatePipe,
    CurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
