import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'monthFormat',
})
export class MonthFormat implements PipeTransform {
  constructor(public translate: TranslateService) {}
  transform(value: number, format: string = 'MMMM'): string {
    if (value != 0) {
      let month;
      if (format == 'MMMM') {
        switch (value) {
          case 1:
            month = this.translate.instant('January');
            break;
          case 2:
            month = this.translate.instant('February');
            break;
          case 3:
            month = this.translate.instant('March');
            break;
          case 4:
            month = this.translate.instant('April');
            break;
          case 5:
            month = this.translate.instant('May');
            break;
          case 6:
            month = this.translate.instant('June');
            break;
          case 7:
            month = this.translate.instant('July');
            break;
          case 8:
            month = this.translate.instant('August');
            break;
          case 9:
            month = this.translate.instant('September');
            break;
          case 10:
            month = this.translate.instant('October');
            break;
          case 11:
            month = this.translate.instant('November');
            break;
          case 12:
            month = this.translate.instant('December');
            break;
          default:
            // if(value == 7) month='July';
            // else month=''
            month = 'Not found';
            break;
        }
        return month;
      }
      if (format == 'MMM') {
        switch (value) {
          case 1:
            month = this.translate.instant('Jan');
            break;
          case 2:
            month = this.translate.instant('Feb');
            break;
          case 3:
            month = this.translate.instant('Mar');
            break;
          case 4:
            month = this.translate.instant('Apr');
            break;
          case 5:
            month = this.translate.instant('May');
            break;
          case 6:
            month = this.translate.instant('Jun');
            break;
          case 7:
            month = this.translate.instant('Jul');
            break;
          case 8:
            month = this.translate.instant('Aug');
            break;
          case 9:
            month = this.translate.instant('Sep');
            break;
          case 10:
            month = this.translate.instant('Oct');
            break;
          case 11:
            month = this.translate.instant('Nov');
            break;
          case 12:
            month = this.translate.instant('Dec');
            break;
          default:
            month = 'Not found';
            break;
        }
        return month;
      }
      if (format == 'MM') {
        switch (value) {
          case 1:
            month = '01';
            break;
          case 2:
            month = '02';
            break;
          case 3:
            month = '03';
            break;
          case 4:
            month = '04';
            break;
          case 5:
            month = '05';
            break;
          case 6:
            month = '06';
            break;
          case 7:
            month = '07';
            break;
          case 8:
            month = '08';
            break;
          case 9:
            month = '09';
            break;
          case 10:
            month = '10';
            break;
          case 11:
            month = '11';
            break;
          case 12:
            month = '12';
            break;
          default:
            month = 'Not found';
            break;
        }
        return month;
      }
      if (format == 'M') {
        switch (value) {
          case 1:
            month = '1';
            break;
          case 2:
            month = '2';
            break;
          case 3:
            month = '3';
            break;
          case 4:
            month = '4';
            break;
          case 5:
            month = '5';
            break;
          case 6:
            month = '6';
            break;
          case 7:
            month = '7';
            break;
          case 8:
            month = '8';
            break;
          case 9:
            month = '9';
            break;
          case 10:
            month = '10';
            break;
          case 11:
            month = '11';
            break;
          case 12:
            month = '12';
            break;
          default:
            month = 'Not found';
            break;
        }
        return month;
      }
    }
    return '';
  }
}
