import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UrlFilePipe } from './pipes/urlFile';
import { RoomFilePipe } from './pipes/roomFile';
@NgModule({
  declarations: [UrlFilePipe, RoomFilePipe],
  imports: [CommonModule],
  exports: [UrlFilePipe, RoomFilePipe],
})
export class UrlFileModule {}
