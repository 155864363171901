<h2 mat-dialog-title class="title">{{ "Change_password" | translate }}</h2>
<mat-dialog-content>
  <form class="content" [formGroup]="resetPasswordForm" (ngSubmit)="submit()">
    <div class="form-group">
      <label class="label" for="old_password">
        {{ "Old_password" | translate }}
      </label>
      <div class="input-group">
        <input
          type="{{ hideOldPassword ? 'password' : 'text' }}"
          class="input"
          id="old_password"
          name="old_password"
          formControlName="oldPasswordCtrl"
          autofocus="off"
          autocomplete="off"
        />
        <!-- <mat-icon svgIcon="eye-close"></mat-icon> -->
        <mat-icon class="icon" (click)="toggleOldPasswordHide()">
          {{ hideOldPassword ? "visibility_off" : "visibility" }}
        </mat-icon>
      </div>
      <span class="error-msg">{{ getErrorMsg("oldPasswordCtrl") }}</span>
    </div>
    <div class="form-group">
      <label class="label" for="new_password">
        {{ "New_password" | translate }}
      </label>
      <div class="input-group">
        <input
          type="{{ hideNewPassword ? 'password' : 'text' }}"
          class="input"
          id="new_password"
          name="new_password"
          formControlName="newPasswordCtrl"
          autofocus="off"
          autocomplete="off"
        />
        <mat-icon class="icon" (click)="toggleNewPasswordHide()">
          {{ hideNewPassword ? "visibility_off" : "visibility" }}
        </mat-icon>
      </div>
      <span class="error-msg">{{ getErrorMsg("newPasswordCtrl") }}</span>
    </div>
    <div class="form-group">
      <label class="label" for="confirm_password">
        {{ "Confirm_new_password" | translate }}
      </label>
      <div class="input-group">
        <input
          type="{{ hideConfirmPassword ? 'password' : 'text' }}"
          class="input"
          id="confirm_password"
          name="confirm_password"
          formControlName="confirmPasswordCtrl"
          autofocus="off"
          autocomplete="off"
        />
        <mat-icon class="icon" (click)="toggleConfirmPasswordHide()">
          {{ hideConfirmPassword ? "visibility_off" : "visibility" }}
        </mat-icon>
      </div>
      <span class="error-msg">{{ getErrorMsg("confirmPasswordCtrl") }}</span>
      <span class="error-msg" *ngIf="passwordNotMatch()">
        {{ "Password_not_match" | translate }}
      </span>
    </div>
    <div class="action-row">
      <button class="button" type="button" (click)="cancel()">
        {{ "Cancel" | translate }}
      </button>
      <button class="button update" type="submit">
        {{ "Update" | translate }}
      </button>
    </div>
  </form>
</mat-dialog-content>
