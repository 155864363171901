import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  organizationID: string;
  constructor(private requestService: RequestService) {}

  getNotifications(data: any = {}) {
    return this.requestService
      .getJSON(`/notifications`, { data: data, is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getAnnouncements(data: any = {}) {
    return this.requestService
      .getJSON(`/notifications/announcement`, { data: data, is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  markAsRead(data: any) {
    return this.requestService
      .postJSON(`/notifications/mark-as-read`, { data, is_loading: true })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
